import React, { useState, useEffect } from 'react';
import { Button, Spin, List, Typography } from 'antd';
import './GetAllProducts.css'; // Import CSS file for styling
import Web3 from 'web3';
import SupplyChainContract from '../../Contracts/Supplychain.json'; // Import the contract ABI
import detectEthereumProvider from '@metamask/detect-provider';
import { Row, Col } from 'antd';

const { Title } = Typography;

const GetAllProducts = () => {
    const [web3, setWeb3] = useState(null);
    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState(null);
    const [hasProvider, setHasProvider] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const initWeb3 = async () => {
            const provider = await detectEthereumProvider();
            setHasProvider(Boolean(provider)); // Set the provider existence
            if (provider) {
                startApp(provider);
            } else {
                console.log('Please install MetaMask!');
            }
        };
        initWeb3();
    }, []);

    const startApp = async (provider) => {
        if (provider !== window.ethereum) {
            console.error('Do you have multiple wallets installed?');
        }
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        const accounts = await web3Instance.eth.getAccounts();
        if (accounts.length > 0) {
            setAccount(accounts[0]);
        }
        const networkId = await web3Instance.eth.net.getId();
        const deployedNetwork = SupplyChainContract.networks[networkId];
        const contractInstance = new web3Instance.eth.Contract(
            SupplyChainContract.abi,
            deployedNetwork && deployedNetwork.address,
        );
        setContract(contractInstance);
    };

    const connectWallet = async () => {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if (accounts.length > 0) {
                setAccount(accounts[0]); // Set the first account as the connected wallet
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAllProducts = async () => {
        setLoading(true);
        if (contract) {
            try {
                const productsCount = await contract.methods.productIdCounter().call();
                const fetchedProducts = [];
                for (let i = 1; i <= productsCount; i++) {
                    const product = await contract.methods.products(i).call();
                    console.log("Products----__>",product)
                    fetchedProducts.push(product);
                }
                setProducts(fetchedProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        }
        setLoading(false);
    };

    return (
        <div className="producer-page">
            <div className='HeaderTab'>
                <Title level={3}>All Products</Title>
                <p className='HeaderDescr'>
                    Access detailed information about all the products stored on the blockchain.
                </p>
            </div>
            <Row justify="center" className="mb-2">
                <Col>
                    <div className="wallet-info">
                        {hasProvider && ( // Only show if provider exists
                            account ? (
                                <>
                                    <p style={{ fontWeight: 'bold' }}>Connected Wallet:</p>
                                    <p style={{ wordWrap: 'break-word' }}>{account}</p>
                                    <Button type="primary" onClick={connectWallet}>Connect Wallet</Button>
                                </>
                            ) : (
                                <Button type="primary" onClick={connectWallet}>Connect Wallet</Button>
                            )
                        )}
                          <div className="wallet-info" style={{ marginTop: '15px' }}>
                            <Button type="primary" onClick={fetchAllProducts} disabled={loading}>
                                Fetch All Products
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            {loading ? (
                <Row justify="center">
                    <Spin size="large" />
                </Row>
            ) : (
                <List
                    dataSource={products}
                    renderItem={(product, index) => (
                        <List.Item>
                            <List.Item.Meta
                                // title={`Product ${index + 1}`}
                                style={{ wordWrap: 'break-word' }}
                                description={
                                    <>
                                        <div style={{display:"flex"}}>
                                            {/* <div style={{fontWeight:"bold",color:"black"}}>
                                            Product :
                                            </div> */}
                                            <div style={{fontWeight:"Bold",color:"black"}}>
                                            {" " + product.productName + " " + "(" +parseInt(product.productId)  + ")"}
                                            </div>
                                           
                                        </div>
                                        <div style={{marginBottom:"2px"}}>
                                            <div style={{fontWeight:"bold",color:"black"}}>
                                            Producer :
                                            </div>
                                            <div style={{wordWrap: 'break-word'}}>
                                                {product.producer}
                                            </div>
                                        </div>
                                        <div style={{marginBottom:"2px"}}>
                                            <div style={{fontWeight:"bold",color:"black"}}>
                                            Distributor :
                                            </div>
                                            <div style={{wordWrap: 'break-word'}}>
                                                {product.distributor}
                                            </div>
                                        </div>
                                        <div style={{marginBottom:"2px"}}>
                                            <div style={{fontWeight:"bold",color:"black"}}>
                                            Retailer :
                                            </div>
                                            <div style={{wordWrap: 'break-word'}}>
                                                {product.retailer}
                                            </div>
                                        </div>
                                        <div style={{marginBottom:"2px"}}>
                                            <div style={{fontWeight:"bold",color:"black"}}>
                                            Consumer :
                                            </div>
                                            <div style={{wordWrap: 'break-word'}}>
                                                {product.consumer}
                                            </div>
                                        </div>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            )}
        </div>
    );
}

export default GetAllProducts;
