
// src/HomePage.jsx
import React, { useState,useEffect } from 'react';
import { Layout, Menu, Segmented,Space,Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import GetAllProducts from '../AllProducts/GetAllProducts';
import GetProduct from '../GetProduct/GetProduct';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import logoImage from "../../images/11authLogo.png"

import './Products.css';
const Products =() => {

  const [activeTab, setActiveTab] = useState('All Products'); // State to track active tab

  const handleTabChange = (value) => {
    setActiveTab(value);
  };
  const navigate = useNavigate();

  const handleNavigateToProducts = () => {
    navigate('/Homepage');
  };
  return (
    <div className="home-page">
      <div className="homePageTabs">
        <div>
          <div>
            <div style={{ width: "85px",marginBottom:"10px" }}>
              <Space align="center">
                <img style={{ width: "85px" }} src={logoImage} alt="Logo" />
              </Space>
            </div>
          </div>
        </div>
        <Space>
              <h1>11Auth Tracker</h1>
              <Button type="primary" onClick={handleNavigateToProducts}>
              Home  <ArrowRightOutlined />
              </Button>
         </Space>
        <Segmented
          defaultValue="All Products"
          style={{
            marginBottom: 8,
          }}
          onChange={handleTabChange}
          options={['All Products', 'Serach products']}
        />
      </div>
      {activeTab === 'All Products'  && <GetAllProducts />}
      {activeTab === 'Serach products' && <GetProduct />}
    </div>
  );
}

export default Products