import React, { useState, useEffect } from 'react';
import { Form, Input, Button,Spin } from 'antd';
import './ProducerPage.css'; // Import CSS file for styling
import Web3 from 'web3';
import SupplyChainContract from '../../Contracts/Supplychain.json'; // Import the contract ABI
import { message } from 'antd';
import detectEthereumProvider from '@metamask/detect-provider';
import { Row, Col } from 'antd';
const ProducerPage = () => {
    const [web3, setWeb3] = useState(null);
    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState(null);
    const [hasProvider, setHasProvider] = useState(null);
    // const [wallet, setWallet] = useState(initialState) 
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const initWeb3 = async () => {
            const provider = await detectEthereumProvider();
            setHasProvider(Boolean(provider)); // Set the provider existence
            if (provider) {
                startApp(provider);
            } else {
                console.log('Please install MetaMask!');
            }
        };
        initWeb3();
    }, []);

    const handleConnect = async () => {  
      let accounts = await window.ethereum.request({  method: "eth_requestAccounts" })  
      if (accounts.length > 0) {
        setAccount(accounts[0]); // Set the first account as the connected wallet
      }  
    }  
    const startApp = async (provider) => {
        if (provider !== window.ethereum) {
            console.error('Do you have multiple wallets installed?');
        }
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        const accounts = await web3Instance.eth.getAccounts();
        if (accounts.length > 0) {
            setAccount(accounts[0]);
        }
        const networkId = await web3Instance.eth.net.getId();
        const deployedNetwork = SupplyChainContract.networks[networkId];
        const contractInstance = new web3Instance.eth.Contract(
            SupplyChainContract.abi,
            deployedNetwork && deployedNetwork.address,
        );
        setContract(contractInstance);
    };

    const connectWallet = async () => {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if (accounts.length > 0) {
                setAccount(accounts[0]); // Set the first account as the connected wallet
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onFinish = async (values) => {
        setLoading(true); // Set loading to true when form is submitted
        const { productName } = values;
        await produceProduct(productName).catch((error) => {
            console.error(error)
            setLoading(false);
        });
        setLoading(false); // Set loading back to false after data is fetched
    };

    const produceProduct = async (productName) => {
        if (contract) {
            try {
                await contract.methods.produceProduct(productName).send({ from: account });
                message.success('Product produced successfully');
            } catch (error) {
                console.error('Error producing product:', error);
            }
        }
    };

    return (
        <div className="producer-page">
            <div className='HeaderTab'>
                <div className='HeaderTitle'>Producer </div>
                <div className='HeaderDescr'>
                    Enter the product details for production
                </div>
            </div>
            <Row justify="center" className="mb-2">
              <Col>
                  <div className="wallet-info">
                      {hasProvider && ( // Only show if provider exists
                          account ? (
                              <>
                                  <p style={{ fontWeight: 'bold' }}>Connected Wallet:</p>
                                  <p style={{ wordWrap: 'break-word' }}>{account}</p>
                                  <Button type="primary" onClick={handleConnect}>Connect Wallet</Button>
                              </>
                          ) : (
                              <Button type="primary" onClick={connectWallet}>Connect Wallet</Button>
                          )
                      )}
                  </div>
              </Col>
          </Row>

          <Spin spinning={loading}> 
            <Form
                name="product_form"
                onFinish={onFinish}
                layout="vertical"
                style={{ marginTop: '15px' }}
            >
                <Form.Item
                    name="productName"
                    label="Product Name"
                    rules={[
                        { required: true, message: 'Please enter product name' }
                    ]}
                >
                    <Input placeholder="Enter product name" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            </Spin>
          
            
        </div>
    );
}

export default ProducerPage;
