import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import './RetailerPage.css'; // Import CSS file for styling
import Web3 from 'web3';
import SupplyChainContract from '../../Contracts/Supplychain.json'; // Import the contract ABI
import { message } from 'antd';
import detectEthereumProvider from '@metamask/detect-provider';
import { Row, Col } from 'antd';
const RetailerPage = () => {
    const [web3, setWeb3] = useState(null);
    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState(null);
    const [hasProvider, setHasProvider] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const initWeb3 = async () => {
            const provider = await detectEthereumProvider();
            setHasProvider(Boolean(provider)); // Set the provider existence
            if (provider) {
                startApp(provider);
            } else {
                console.log('Please install MetaMask!');
            }
        };
        initWeb3();
    }, []);

    const handleConnect = async () => {
        let accounts = await window.ethereum.request({ method: "eth_requestAccounts" })
        if (accounts.length > 0) {
            setAccount(accounts[0]); // Set the first account as the connected wallet
        }
    }
    const startApp = async (provider) => {
        if (provider !== window.ethereum) {
            console.error('Do you have multiple wallets installed?');
        }
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        const accounts = await web3Instance.eth.getAccounts();
        if (accounts.length > 0) {
            setAccount(accounts[0]);
        }
        const networkId = await web3Instance.eth.net.getId();
        const deployedNetwork = SupplyChainContract.networks[networkId];
        const contractInstance = new web3Instance.eth.Contract(
            SupplyChainContract.abi,
            deployedNetwork && deployedNetwork.address,
        );
        setContract(contractInstance);
    };

    const connectWallet = async () => {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if (accounts.length > 0) {
                setAccount(accounts[0]); // Set the first account as the connected wallet
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onFinish = async (values) => {
        setLoading(true); // Set loading to true when form is submitted
        const { productId, retailerAddress } = values;
        await productRetailed(productId, retailerAddress).catch((error) => {
            console.error(error)
            setLoading(false);
        });
        setLoading(false); // Set loading back to false after data is fetched
    };

    const productRetailed = async (productId, retailerAddress) => {
        if (contract) {
            try {
                await contract.methods.retailProduct(productId, retailerAddress).send({ from: account });
                message.success('Product retailed successfully');
            } catch (error) {
                console.error('Error retailed product:', error);
            }
        }
    };

    return (
        <div className="producer-page">
            <div className='HeaderTab'>
                <div className='HeaderTitle'>Retailer </div>
                <div className='HeaderDescr'>
                    Enter the product details for Retail
                </div>
            </div>
            <Row justify="center" className="mb-2">
                <Col>
                    <div className="wallet-info">
                        {hasProvider && ( // Only show if provider exists
                            account ? (
                                <>
                                    <p style={{ fontWeight: 'bold' }}>Connected Wallet:</p>
                                    <p style={{ wordWrap: 'break-word' }}>{account}</p>
                                    <Button type="primary" onClick={handleConnect}>Connect Wallet</Button>
                                </>
                            ) : (
                                <Button type="primary" onClick={connectWallet}>Connect Wallet</Button>
                            )
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">

            </Row>
            <Spin spinning={loading}>
                <Form
                    name="product_form"
                    onFinish={onFinish}
                    layout="vertical"
                    style={{ marginTop: '15px' }}
                >
                    <Form.Item
                        name="productId"
                        label="Product ID"
                        rules={[
                            { required: true, message: 'Please Enter Product Id' }
                        ]}
                    >
                        <Input placeholder="Enter Product Id" />
                    </Form.Item>
                    <Form.Item
                        name="retailerAddress"
                        label="Retailer Address"
                        rules={[
                            { required: true, message: 'Enter Retailer Address' }
                        ]}
                    >
                        <Input placeholder="Enter Retailer Address" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
}

export default RetailerPage;
