// src/HomePage.jsx
import React, { useState, useEffect } from "react";
import { Layout, Menu, Segmented, Button, Space, Avatar } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import ProducerPage from "../Producer/ProducerPage"; // Import ProducerPage component
import "./HomePage.css"; // Import CSS file for styling
import ManufacturerPage from "../Manufacturer/ManufacturerPage";
import RetailerPage from "../Retailer/RetailerPage";
import ConsumerPage from "../consumer/ConsumerPage";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import logoImage from "../../images/11authLogo.png";
const HomePage = () => {
  const [activeTab, setActiveTab] = useState("Producer"); // State to track active tab

  const handleTabChange = (value) => {
    setActiveTab(value);
  };
  const navigate = useNavigate();
  useEffect(() => {
    const rzpPaymentForm = document.getElementById("rzp_payment_form");

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_NkbnyJUUBkHycY";
      rzpPaymentForm.appendChild(script);
    }
  });

  const handleNavigateToProducts = () => {
    navigate("/Products");
  };
  return (
    <>
    <div className="home-page">
      <div className="homePageTabs">
        <div>
          <div>
            <div style={{ width: "85px",marginBottom:"10px" }}>
              <Space align="center">
                <img style={{ width: "85px" }} src={logoImage} alt="Logo" />
              </Space>
            </div>
          </div>
        </div>
        <Space>
          <h1>11Auth Tracker</h1>
          <Button type="primary" onClick={handleNavigateToProducts}>
            Product <ArrowRightOutlined />
          </Button>
        </Space>
        <Segmented
          defaultValue="producer"
          style={{
            marginBottom: 8,
          }}
          onChange={handleTabChange}
          options={["Producer", "Distributor", "Retailer", "Consumer"]}
        />
      </div>
      {activeTab === "Producer" && <ProducerPage />}
      {activeTab === "Distributor" && <ManufacturerPage />}
      {activeTab === "Retailer" && <RetailerPage />}

      {activeTab === "Consumer" && <ConsumerPage />}
    </div>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Space align="center">
          <form id="rzp_payment_form"></form>
        </Space>
      </div>
    </>
  );
};

export default HomePage;
