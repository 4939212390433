import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import HomePage from './Components/Homepage/HomePage';
import Products from './Components/Products/Products';

    function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path='/HomePage' element={<HomePage />}/>
            <Route path='/Products' element={<Products />}/>
            <Route path="/" element={<HomePage />} > 
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;